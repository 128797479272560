import dateFormat from 'dateformat';
import * as moment from "moment";
let dateFilters = [
    'Today',
    'Tomorrow',
    'Yesterday',
    'This Week',
    'Next Week',
    'Last Week',
    'This Month',
    'Next Month',
    'Last Month',
    'Next 7 days',
    'Next 30 days',
    'Next 60 days',
    'Next 90 days',
    'Last 7 days',
    'Last 30 days',
    'Last 60 days',
    'Last 90 days',
    'This Year',
    'Last Year',
    'Exact Date',
    'Date Range',
    'Next',
    'Last',
    'No Date'
]
function formatDate(date) {
    let isoFormat = null;
    let week = null;
    if (date) {
        isoFormat = dateFormat(new Date(date), "yyyy-mm-dd");
        week = dateFormat(new Date(date), "yyyy - W")
    }
    return {
        isoFormat,
        week
    };
  }
function dateFilterSwitch(selectedFilter) {
    switch(selectedFilter) {
        case 'Today':
            return {
                dateType: formatDate(new Date()).isoFormat, 
                week: formatDate(new Date()).week
            }
        case 'Tomorrow':
            return {
                dateType: formatDate(moment().add(1, 'days')).isoFormat, 
                week: formatDate(moment().add(1, 'days')).week
            }
        case 'Yesterday':
            return {
                dateType: formatDate(moment().subtract(1, 'days')).isoFormat, 
                week: formatDate(moment().subtract(1, 'days')).week
            }
        case 'This Week':
            return {
                dateType: {
                    start: formatDate(moment().startOf('week')).isoFormat, 
                    end: formatDate(moment().endOf('week')).isoFormat
                }, 
                week: formatDate(moment().startOf('week')).week
            }
        case 'Next Week':
            return {
                dateType: {
                    start: formatDate(moment().add(1, 'week').startOf('week')).isoFormat, 
                    end: formatDate(moment().add(1, 'week').endOf('week')).isoFormat 
                }, 
                week: formatDate(moment().add(1, 'week').startOf('week')).week
            }
        case 'Last Week':
            return {
                dateType: {
                    start: formatDate(moment().subtract(1, 'week').startOf('week')).isoFormat, 
                    end: formatDate(moment().subtract(1, 'week').endOf('week')).isoFormat
                }, 
                week: formatDate(moment().subtract(1, 'week').startOf('week')).week
            }
        case 'This Month':
            return {
                dateType: {
                    start:formatDate(moment().startOf('month')).isoFormat,
                    end: formatDate(moment().endOf('month')).isoFormat
                }, 
                week: {
                    start: formatDate(moment().startOf('month')).week,
                    end: formatDate(moment().endOf('month')).week
                }
            }
        case 'Next Month':
            return {
                dateType: {
                    start: formatDate(moment().add(1, 'month').startOf('month')).isoFormat,
                    end: formatDate(moment().add(1, 'month').endOf('month')).isoFormat
                }, 
                week: {
                    start: formatDate(moment().add(1, 'month').startOf('month')).week,
                    end: formatDate(moment().add(1, 'month').endOf('month')).week
                }
            }
        case 'Last Month':
            return {
                dateType: {
                    start: formatDate(moment().subtract(1, 'month').startOf('month')).isoFormat,
                    end: formatDate(moment().subtract(1, 'month').endOf('month')).isoFormat
                }, 
                week: {
                    start: formatDate(moment().subtract(1, 'month').startOf('month')).week,
                    end: formatDate(moment().subtract(1, 'month').endOf('month')).week
                }
            }
        case 'Next 7 days':
            return {
                dateType: {
                    start: formatDate(moment()).isoFormat,
                    end: formatDate(moment().add(7, 'days')).isoFormat
                }, 
                week: {
                    start: formatDate(moment()).week,
                    end: formatDate(moment().add(7, 'days')).week
                }
            }
        case 'Next 30 days':
            return {
                dateType: {
                    start: formatDate(moment()).isoFormat,
                    end: formatDate(moment().add(30, 'days')).isoFormat
                }, 
                week: {
                    start: formatDate(moment()).week,
                    end: formatDate(moment().add(30, 'days')).week
                }
            }
        case 'Next 60 days':
            return {
                dateType: {
                    start: formatDate(moment()).isoFormat,
                    end: formatDate(moment().add(60, 'days')).isoFormat
                }, 
                week: {
                    start: formatDate(moment()).week,
                    end: formatDate(moment().add(60, 'days')).week
                }
            }
        case 'Next 90 days':
            return {
                dateType: {
                    start: formatDate(moment()).isoFormat,
                    end: formatDate(moment().add(90, 'days')).isoFormat
                }, 
                week: {
                    start: formatDate(moment()).week,
                    end: formatDate(moment().add(90, 'days')).week
                }
            }
        case 'Last 7 days':
            return {
                dateType: {
                    start: formatDate(moment().subtract(7, 'days')).isoFormat,
                    end: formatDate(moment()).isoFormat
                }, 
                week: {
                    start: formatDate(moment().subtract(7, 'days')).week,
                    end: formatDate(moment()).week
                }
            }
        case 'Last 30 days':
            return {
                dateType: {
                    start: formatDate(moment().subtract(30, 'days')).isoFormat,
                    end: formatDate(moment()).isoFormat
                }, 
                week: {
                    start: formatDate(moment().subtract(30, 'days')).week,
                    end: formatDate(moment()).week
                }
            }
        case 'Last 60 days':
            return {
                dateType: {
                    start: formatDate(moment().subtract(60, 'days')).isoFormat,
                    end: formatDate(moment()).isoFormat
                }, 
                week: {
                    start: formatDate(moment().subtract(60, 'days')).week,
                    end: formatDate(moment()).week
                }
            }
        case 'Last 90 days':
            return {
                dateType: {
                    start: formatDate(moment().subtract(90, 'days')).isoFormat,
                    end: formatDate(moment()).isoFormat
                }, 
                week: {
                    start: formatDate(moment().subtract(90, 'days')).week,
                    end: formatDate(moment()).week
                }
            }
        case 'This Year':
            return {
                dateType: {
                    start: formatDate(moment().startOf('year')).isoFormat,
                    end: formatDate(moment().endOf('year')).isoFormat
                }, 
                week: {
                    start: formatDate(moment().startOf('year')).week,
                    end: formatDate(moment().endOf('year').subtract(2, 'days')).week
                }
            }
        case 'Last Year':
            return {
                dateType: {
                    start: formatDate(moment().subtract(1, 'year').startOf('year')).isoFormat,
                    end: formatDate(moment().subtract(1, 'year').endOf('year')).isoFormat
                }, 
                week: {
                    start: formatDate(moment().subtract(1, 'year').startOf('year').add(1, 'day')).week,
                    end: formatDate(moment().subtract(1, 'year').endOf('year')).week
                }
            }
        default:
            return {dateType: null, week: null}
    }
}
function handleDate(selectedFilter, date, dateType) {
    let result;
    if(selectedFilter == 'No Date') {
        result = date == null || date == '' ? true : false;
    } else {
        let dateFilter = dateFilterSwitch(selectedFilter);
        if(dateFilter) {
            if(!dateType) {
                if(typeof dateFilter.dateType === 'object'){
                    result = date >= dateFilter.dateType.start && date <= dateFilter.dateType.end ? true : false;
                } else {
                    result = formatDate(date) == dateFilter.dateType ? true : false;
                }
            } else {
                if(typeof dateFilter.week === 'object'){
                    result = date >= dateFilter.week.start && date <= dateFilter.week.end ? true : false;
                } else {
                    result = date == dateFilter.week ? true : false;
                }
            }
        } else {
            result = false;
        }
    }
    return result;
}
function applyFilter(item, field, value, operator) {
    switch(operator.toLowerCase()) {
        case 'is':
            return item[field] == value;
        case 'is not':
            return item[field] != value;
        case 'includes':
            return item[field].includes(value);
        case 'excludes':
            return !item[field].includes(value);
        case 'arrayincludes':
            return item.includes(value);
        case 'arrayexcludes':
            return !item.includes(value)
        default:
            return false;
    }
}

function applyBlockFilters(block, item) {
    return block.filters.reduce((result, filter) => {
        let filterResult = false;
        if (filter.field && filter.value && filter.operator) {
            if (!['features', 'date', 'country'].includes(filter.type) && !['stockAllocationStatus', 'fileStatus'].includes(filter.field)) {
                filterResult = applyFilter(item, filter.field, filter.value, filter.operator);
            } else if (filter.type == 'country') {
                filterResult = applyFilter(item, filter.field, filter.selectedCountry.name, filter.operator);
            } else if (filter.type == 'features') {
                if (item.temporaryProducts.length > 0) {
                    let products = item.temporaryProducts.map(x => x.product);
                    let product = applyFilter(products, filter.field, filter.value, filter.operator == 'Is' ? 'arrayincludes' : 'arrayexcludes');
                    if (filter.selectedVariation) {
                        let variations = item.temporaryProducts.map(x => x.variation);
                        let variation = applyFilter(variations, filter.field, filter.selectedVariation, filter.operator == 'Is' ? 'arrayincludes' : 'arrayexcludes');
                        filterResult = product && variation;
                    } else {
                        filterResult = product;
                    }
                }
            } else if (filter.type == 'date') {
                if (filter.field == 'etaWeekYear' || filter.field == 'etdWeekYear') {
                    let dateRes = handleDate(filter.value, item[filter.field], 'week');
                    filterResult = filter.operator == 'Is' ? dateRes : !dateRes;
                } else {
                    let dateRes = handleDate(filter.value, item[filter.field]);
                    filterResult = filter.operator == 'Is' ? dateRes : !dateRes;
                }
            } else if(filter.field == 'stockAllocationStatus') {
                if(filter.value == 'Full') {
                    filterResult = item.allocatedPallets == item.totalPallets && item.totalPallets > 0;
                } else if(filter.value == 'Partial') {
                    filterResult = item.allocatedPallets < item.totalPallets && item.allocatedPallets > 0;
                } else if(filter.value == 'None') {
                    filterResult = item.allocatedPallets == 0 && item.totalPallets; 
                } else if(filter.value == 'Issue') {
                    filterResult = !item.totalPallets && item.allocatedPallets
                }
            } else if(filter.field == 'fileStatus') {
                if(filter.value == 'Ready to Send') {
                    filterResult = item.fileStatus == 'Ready';
                } else if(filter.value == 'Sent') {
                    filterResult = item.fileStatus == 'Sent';
                } else if(filter.value == 'Not Ready') {
                    filterResult = item.fileStatus != 'Ready' && item.fileStatus != 'Sent' && item.fileStatus;
                } else if(filter.value == 'Not Set') {
                    filterResult = !item.fileStatus
                }
            }
        } else if (filter.field && filter.operator) {
            if (filter.type == 'countryPort' && filter.selectedCountry?.countryCode) {
                filterResult = applyFilter(item, filter.field, filter.selectedCountry.countryCode, filter.operator == 'Is' ? 'includes' : 'excludes');
            }
        }
        return block.andOr === 'AND' ? result && filterResult : result || filterResult;
    }, block.andOr === 'AND');
}

function filterData(filters, item) {
    return filters.reduce((result, filter) => {
        if (filter.blocks && filter.blocks.length > 0) {
            const blockResults = filter.blocks.map(block => applyBlockFilters(block, item));
            const finalResult = filter.andOr === 'AND' ? blockResults.every(Boolean) : blockResults.some(Boolean);
            return result && finalResult;
        } else {
            return result;
        }
    }, true);
}


export default {
    filterData
}